import loadable from "@loadable/component";
import { Suspense } from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";

import { ToastContainer } from "react-toastify";
import Spinner from "./@core/components/spinner/Fallback-spinner";

import "./@core/components/ripple-button";
import "react-perfect-scrollbar/dist/css/styles.css";

import "@styles/react/libs/toastify/toastify.scss";

import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";
import "./assets/scss/DropdownStyle.scss";
import "./views/components/SettingComp/settingStyle.scss";

import * as serviceWorker from "./serviceWorker";
import { SocketContext, socket } from "./service/socket";

import { registerServiceWorker } from "./serviceWorker";
const LazyApp = loadable(() => import("./App"));

ReactDOM.render(
  <SocketContext.Provider value={socket}>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <LazyApp />
        <ToastContainer newestOnTop />
      </Suspense>
    </Provider>
  </SocketContext.Provider>,
  document.getElementById("root")
);

serviceWorker.register();
registerServiceWorker();
